<template>
  <div>
    <laboratories-component />
    <footer-component />
  </div>
</template>

<script>
import LaboratoriesComponent from "@/components/laboratories/LaboratoriesComponent";
import FooterComponent from "@/layouts/app-layout/footer/FooterComponent";
import { changeInformationSEO } from "@/providers/KeywordsServices";

export default {
  name: "Laboratories",
  title: "Top 10 Laboratories | Baja California Health Tourism",
  components: { FooterComponent, LaboratoriesComponent },
  mounted() {
    changeInformationSEO();
  }
};
</script>

<style scoped></style>
